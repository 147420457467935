<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>文件名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.name" placeholder="请输入文件名" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>导出类型：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.type" placeholder="请选择" clearable>
                        <el-option
                                v-for="item in typeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>任务创建时间：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <!--<div class="baseOperate clearfloat">-->
        <!--<div class="baseOperateOne">-->
        <!--<el-button type="primary" @click="search">刷新列表</el-button>-->
        <!--</div>-->
        <!--</div>-->
        <div class="baseTable" v-loading="tableLoading" style="margin-top: 10px">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         ref="publicTableUserStudent">
                <el-table-column prop="created_at" label="任务创建时间" width="110"></el-table-column>
                <el-table-column prop="file_name" label="文件名"></el-table-column>
                <el-table-column prop="user_name" label="操作人员"></el-table-column>
                <el-table-column prop="statusName" label="状态"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" v-if="scope.row.status == 1" @click.prevent="openExport(scope.row)">
                            下载文件
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import {businessGet} from '../../api/business.js'

    import Global from "../../Global";

    export default {
        name: "recruitPlan",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                typeList: [],
                searchForm: {
                    type: ''
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                total: 0,
            }
        },
        mounted() {
            this.getTypeList();
            this.$nextTick(() => {
                let baseOperateDom = document.querySelector('.baseSearch');
                let searchHeight = baseOperateDom.offsetHeight + 10;
                this.searchHeight = searchHeight;
                this.getTableData()
            })
        },
        methods: {
            openExport(val) {
                window.open(val.file_path);
            },
            cleanAll() {
                this.searchForm.name = '';
                this.searchForm.type = '';
                this.searchForm.time = [];
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            exportExcel() {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            getTableData() {
                let url = '/admin/v1/download?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            item.statusName = Global.Global_export_status_obj[item.status];
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getTypeList() {
                let url = '/admin/v1/download/type';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let typeList = [];
                        for (let k in res.data.data) {
                            let one = {
                                value: k,
                                label: res.data.data[k]
                            }
                            typeList.push(one)
                        }
                        this.typeList = typeList;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
